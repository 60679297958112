body {
  margin: 0;
  /*height: 100%;*/
  min-height: 100%;
  display: flex; /* establish flex container */
  flex-direction: column; /* align child elements ("flex items") vertically */
  justify-content: space-between;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  margin: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  margin: 0;
  height: 100%;
}
