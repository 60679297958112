.animationPulse {
  transform: scale(1);
  animation: pulse 1s infinite ease-in-out alternate;
}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1.2);
  }
}
