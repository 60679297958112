.imagen {
  height: 7rem;
  width: 100%;
  object-fit: cover;
  margin-top: 1rem;
}

.titulo {
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: black;
  padding: 0.5em 0;
  text-align: left;
  padding-left: 3rem;
  text-shadow: 1px 1px 2px white, 0 0 1em white, 0 0 0.5em white;
  background: linear-gradient(to right, white 1%, #c3d0d8);
  height: 7rem;
}

.noPaddingInline {
  padding-inline: 0 !important;
}