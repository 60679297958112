.subtitulo {
  margin-top: 6rem;
  font-size: 2rem;
  background: #367696;
  border-radius: 0.25em;
  color: white;
  padding: 0.5em 0;
  padding-left: 20px;
  text-align: left;
  padding-left: 3rem;
}
