.maincontainer {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Jost", sans-serif;
  background: linear-gradient(0.9turn, #bfd0d8, #1484b4, #0d5475);
}
.main {
  width: 350px;
  height: 500px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 5px 20px 50px #000;
}
.chk {
  display: none;
}
.signup {
  position: relative;
  width: 100%;
  height: 100%;
}
.label {
  color: #fff;
  font-size: 1.5em;
  justify-content: center;
  display: flex;
  margin-left: 60px;
  margin-right: 60px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
.div {
  display: flex;
}
.input {
  width: 60%;
  background: rgba(255, 243, 243, 0.1);
  justify-content: center;
  display: flex;
  margin: 20px auto;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  border-width: 1px;
}

.inputregister {
  width: 60%;
  background: #e0dede;
  justify-content: center;
  display: flex;
  margin: 20px auto;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #04547c;
}

.button {
  width: 60%;
  height: 40px;
  margin: 10px auto;
  justify-content: center;
  display: block;
  color: #fff;
  background: #04547c;
  font-size: 1em;
  font-weight: bold;
  margin-top: 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}
.button:hover {
  background: #8cdcf4;
}
.login {
  height: 500px;
  background: #eee;
  border-top-left-radius: 60% 10%;
  border-top-right-radius: 60% 10%;
  transform: translateY(-100px);
  transition: 0.8s ease-in-out;
}
.login label {
  color: #04547c;
  transform: scale(0.6);
}

.chk:checked ~ .login {
  transform: translateY(-500px);
}
.chk:checked ~ .login label {
  transform: scale(1);
}
.chk:checked ~ .signup label {
  transform: scale(0.6);
}

.cogs {
  color: #e0dede;
  margin: auto;
  margin-top: 30px;
  display: block;
}
