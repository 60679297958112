.navlink {
  display: block;
  margin-right: 20px;
  color: #0d5475;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.dropItem a {
  color: #0d5475;
  font-weight: bold;
}
.dropdownItem {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.cerrarsesion {
  border: 2px solid #0d5475;
  background-color: white;
  color: #0d5475;
  padding: 3px 9px;
  font-weight: bold;
}
