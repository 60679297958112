.spinnerContainer {
  margin-top: 10%;
}

.subtitulo {
  font: bold 100% sans-serif;
  background: #265679;
  border-radius: 0.25em;
  color: white;
  padding: 0.5em 0;
  text-align: left;
  width: 98% !important;
}

.labelBackground {
  background: #c3d0d8;
}
