.subtitulo {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: black;
  padding: 0.5em 0;
  text-align: left;
  padding-left: 3rem;
  text-shadow: 1px 1px 2px white, 0 0 1em white, 0 0 0.5em white;
  background: linear-gradient(to right, white 1%, #92c0e0);
  height: 6rem;
}
