.spinnerContainer {
  margin-top: 10%;
}
.botonAdd {
  background-color: #924b84;
  border-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.subtitulo {
  margin-top: 1rem;
  font-size: 2rem;
  background: #367696;
  border-radius: 0.25em;
  color: white;
  padding: 0.5em 0;
  padding-left: 20px;
  text-align: left;
  padding-left: 3rem;
}
