.maincontainer {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Jost", sans-serif;
  background: linear-gradient(to bottom, #2e86c1, #110995);
}
.main {
  width: 350px;
  height: 500px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 5px 20px 50px #000;
}
.signup {
  position: relative;
  width: 100%;
  height: 100%;
}
.div {
  display: flex;
}

.cogs {
  color: #e0dede;
  margin: auto;
  margin-top: 30px;
  display: block;
}

.input {
  width: 60%;
  background: rgba(255, 255, 255, 0.1);
  justify-content: center;
  display: flex;
  margin: 20px auto;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  border-width: 1px;
}

.label {
  color: #fff;
  font-size: 2em;
  justify-content: center;
  display: flex;
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 60px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
.button {
  width: 60%;
  height: 40px;
  margin: 10px auto;
  justify-content: center;
  display: block;
  color: #fff;
  background: #21618c;
  font-size: 1em;
  font-weight: bold;
  margin-top: 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}
.button:hover {
  background: #2874a6;
}
