.singleTimelineContent {
  position: relative;
  z-index: 1;
  padding: 30px 30px 25px;
  border-radius: 6px;
  margin-bottom: 15px;
  margin-top: 15px;
  -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  border: 1px solid #ebebeb;
}

@media only screen and (max-width: 575px) {
  .singleTimelineXontent {
    padding: 20px;
  }
}
.singleTimelineContent .timelineIcon {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  width: 30px;
  height: 30px;
  background-color: #1484b4;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  text-align: center;
  max-width: 30px;
  border-radius: 50%;
  margin-right: 15px;
}

.singleTimelineContent .timelineIcon i {
  color: #ffffff;
  line-height: 30px;
}
.singleTimelineContent .timelineText h6 {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
.singleTimelineContent .timelineText p {
  font-size: 13px;
  margin-bottom: 0;
}
.singleTimelineContent:hover .timelineIcon,
.singleTimelineContent:focus .timelineIcon {
  background-color: #020710;
}
.singleTimelineContent:hover .timelineText h6,
.singleTimelineContent:focus .timelineText h6 {
  color: #04547c;
}
